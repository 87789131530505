@import "../assets/fonts/stylesheet.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: syne;
  src: url("../assets/fonts/Syne-VariableFont_wght.ttf");
}
@font-face {
  font-family: geolacia;
  src: url("../assets/fonts/Geologica-VariableFont_CRSV,SHRP,slnt,wght.ttf");
}
@font-face {
  font-family: dmsans;
  src: url("../assets/fonts/DMSans-VariableFont_opsz,wght.ttf");
}
.font-syne {
  font-family: syne;
}
.font-pilat {
  font-family: Pilat Extended;
}
.font-dm-sans {
  font-family: dmsans;
  font-style: normal;
}
.font-geolacia {
  font-family: geolacia;
}
* {
  scroll-behavior: smooth;
}
html {
  background: #fff;
}
:root {
  height: 100dvh;
}
body {
  padding: 0px;
  margin: 0px;
  height: 100dvh;
  background: #FCFDFD;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
.bred {
  border: 1px solid red;
}
.max-width {
  max-width: 1400px;
}
.bold {
  font-weight: bold !important;
}
* {
  font-family: dmsans;
  font-style: normal;
}
.text-purple {
  color: #5e57f2;
}
.text-tomato {
  color: #fe4e40;
}
.bg-tomato {
  background: #fe4e40;
}
.bg-purple {
  background: #5e57f2;
}
.bg-lime-500 {
  background: #bdff05;
}
.text-lime-500 {
  color: #bdff05;
}
.text-base-100 {
  color: '#0C0B1E';
}
.text-cemter {
  text-align: center;
}
.bg-purple-100 {
  background: #F0F0F8;
}
.text-purple-100 {
  color: #F0F0F8;
}
.bg-purple-300 {
  background: #D4D3EA;
}
.text-purple-100 {
  color: #F0F0F8;
}
.copyright {
  border-top: 1px solid #ffffff4c;
}
